<template>
  <div class="service-solution">
    <v-banner :data="swiperData" />
    <v-nav-bar :data="navBarList" />
    <v-solution-overview :data="solutionOverviewData" />
    <v-pain-difficult :data="painDifficultData" />
    <v-functional-framework :imgurl="functionalFramework" />
    <div
      class="
        banner100vw banner100vw-20p30t banner100vw-linear-gradient
        scheme-advantages-bg
      "
      id="advantages"
    >
      <v-scheme-advantages
        :typeslist1="typesList1"
        :typeslist2="typesList2"
      />
    </div>
    <v-customer-stories :case-list="caseList" />
  </div>
</template>

<script>
// NOTE :  企业招标采购综合服务解决方案
import vNavBar from './components/navBar/index.vue'
import vBanner from './components/banner/index.vue'
import vSolutionOverview from './components/solutionOverview/index.vue'
import vPainDifficult from './components/painDifficult/index.vue'
import vFunctionalFramework from './components/functionalFramework/index.vue'
import vSchemeAdvantages from './components/schemeAdvantages/index.vue'
import vCustomerStories from './components/customerStories/index.vue'
// vPainDifficult
export default {
  components: {
    vNavBar,
    vBanner,
    vSolutionOverview,
    vPainDifficult,
    vFunctionalFramework,
    vSchemeAdvantages,
    vCustomerStories
  },
  computed: {
    typesList1 () {
      return this.schemeAdvantages.slice(0, 3)
    },
    typesList2 () {
      return this.schemeAdvantages.slice(3, 5)
    }
  },
  data () {
    return {
      swiperData: [],
      navBarList: [
        {
          name: '方案详情',
          ref: 'overview'
        },
        {
          name: '难点痛点',
          ref: 'difficult'
        },
        {
          name: '功能框架',
          ref: 'framework'
        },
        {
          name: '方案优势',
          ref: 'advantages'
        },
        {
          name: '客户案例',
          ref: 'stories'
        }
      ],
      solutionOverviewData: {
        subTitleData:
          '文锐为企业的招标采购活动提供全流程电子化服务，帮助企业进一步规范采购行为，解决企业采购信息公开渠道受限、管理手段落后、采购成本高、效率低等问题，助力企业建立现代化的供应链体系。',
        list: []
      },
      painDifficultData: {
        typeList: [
          {
            img: '/static/img/theSolution/c-1.png',
            title: '采购制度建设与落实方面',
            content:
              '企业采购管理制度缺失或不完善或采购制度落实无抓手，招标采购流程不规范存在暗箱操作的风险'
          },
          {
            img: '/static/img/theSolution/c-2.png',
            title: '采购需求方面',
            content:
              '企业生产用物资采购频繁、需求紧急、技术指标不确定、采购内容随时调整等特性，大多数采购都无法按照招标的方式进行采购。'
          },
          {
            img: '/static/img/theSolution/c-3.png',
            title: '采购信息化方面',
            content:
              '采购工作方式落后、电子化程度低，采购效率低下、交易成本高，采购相关数据无法应用于企业决策支撑。'
          },
          {
            img: '/static/img/theSolution/c-4.png',
            title: '集中采购方面',
            content:
              '大型企业集团的分子公司分散采购，无法形成规模效应，无法实现供应商资源的共享。'
          },
          {
            img: '/static/img/theSolution/c-5.png',
            title: '库存管理方面',
            content:
              '原燃材料存在季节性、周期性或其他各种客观原因导致的价格波动大、不能如期供货的因素，企业动态的采购计划与库存管理难度较大'
          },
          {
            img: '/static/img/theSolution/c-6.png',
            title: '采购监管方面',
            content:
              '非招标采购方式没有固定的采购模式，存在采购程序不规范、采购过程不透明等问题'
          }
        ],
        solution: {
          title: '《企业招标采购综合服务解决方案》',
          content:
            '文锐为企业的招标采购活动提供全流程电子化服务，帮助企业进一步规范采购行为，解决企业采购信息公开渠道受限、管理手段落后、采购成本高、效率低等问题，助力企业建立现代化的供应链体系。',
          img: '/static/img/theSolution/solution-download-r.png'
        }
      },
      functionalFramework: 'static/img/theSolution/functional-framework.png',
      schemeAdvantages: [
        {
          img: '/static/img/theSolution/d-1.png',
          title: '交易更灵活',
          content:
            '公共资源交易信息化行业经验与国有企业招标采购实际需求相结合，兼顾合法合规与采购效率'
        },
        {
          img: '/static/img/theSolution/d-2.png',
          title: '区块链技术',
          content:
            '可以通过区块链智能合约固化交易流程和交易规则，确保交易程序的运行不受人为干预，做到真正的公开透明'
        },
        {
          img: '/static/img/theSolution/d-3.png',
          title: '大数据技术',
          content:
            '可以通过区块链智能合约固化交易流程和交易规则，确保交易程序的运行不受人为干预，做到真正的公开透明'
        },
        {
          img: '/static/img/theSolution/d-4.png',
          title: '人工智能技术',
          content:
            '与交易场所的门禁、监控系统无缝集成，可实现交易场所的无人值守见证与监控，自动对交易场所内的人员和行为进行打点监控。'
        },
        {
          img: '/static/img/theSolution/d-5.png',
          title: '阿里云',
          content:
            '支持企业招采过程中特殊授标方式，单项最低价授标，为企业争取最优采购价；'
        }
      ],
      caseList: []
    }
  },
  methods: {
    /**
     * 获取轮播图
     */
    getSwiperData () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.bannerTypes['企业招标采购综合服务解决方案']
      }
      this.swiperData = []
      this.$api
        .getBannerInfo(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          this.swiperData = res.data
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    // 获取案例 - 企业招标采购综合服务解决方案
    getCaseList () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.caseTypes['企业招标采购综合服务解决方案']
      }
      this.$api.getCaseInfo(params).then(({ data: res }) => {
        this.loading = false
        if (res.code !== 0) {
          return console.error(res)
        }
        this.caseList = res.data
      }).catch((err) => {
        this.loading = false
        console.error(err)
      })
    },
    // 获取方案 - 企业招标采购综合服务解决方案
    getProgrammeList () {
      const params = {
        parentId: this.$siteTypes.programmeTypes['企业招标采购综合服务解决方案']
      }
      this.$api.getProgrammeInfo(params).then(({ data: res }) => {
        if (res.code !== 0) {
          return console.error(res)
        }
        this.solutionOverviewData.list = res.data
      }).catch((err) => {
        console.error(err)
      })
    }
  },
  mounted () {
    this.getSwiperData()
    this.getCaseList()
    this.getProgrammeList()
  }
}
</script>

<style lang="less" scoped>
.service-solution {
  .banner100vw();
  .scheme-advantages-bg {
    &::before {
      background: url('/static/img/theSolution/a-1.png') no-repeat center center /
          100% auto,
        linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
    }
  }
}
</style>
